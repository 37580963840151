export const VIRUS_STATUS = Object.freeze({
  STATUS_NOT_SCANNED: "not_scanned",
  STATUS_SCANNING: "scanning",
  STATUS_SCAN_PASSED: "scanned",
  STATUS_SCAN_FAILED: "failed_scan",
  STATUS_EMPTY: "",
  STATUS_TOO_LARGE: "too_large",
});

export const FILE_SIZE_LIMIT = 5368000000;
export const FILE_SIZE_TEXT_LIMIT = "5GB";

export const PREVIEWABLE_FILE_TYPES = {
  bmp: true,
  csv: true,
  cur: true,
  dib: true,
  doc: true,
  docm: true,
  docx: true,
  dot: true,
  dotm: true,
  dotx: true,
  emf: true,
  eml: true,
  fodg: true,
  fodp: true,
  fods: true,
  fodt: true,
  gif: true,
  heic: true,
  htm: true,
  html: true,
  ico: true,
  jp2: true,
  jpc: true,
  jpeg: true,
  jpg: true,
  msg: true,
  odf: true,
  odg: true,
  odp: true,
  ods: true,
  odt: true,
  otg: true,
  otp: true,
  ots: true,
  pbm: true,
  pdf: true,
  pgm: true,
  png: true,
  pot: true,
  potm: true,
  potx: true,
  ppm: true,
  pps: true,
  ppsm: true,
  ppsx: true,
  ppt: true,
  pptm: true,
  pptx: true,
  psb: true,
  psd: true,
  rtf: true,
  tif: true,
  tiff: true,
  txt: true,
  vdx: true,
  vsd: true,
  vsdm: true,
  vsdx: true,
  xhtm: true,
  xhtml: true,
  xls: true,
  xlsm: true,
  xlsx: true,
  xlt: true,
  xltm: true,
  xltx: true,
  xod: true,
  xps: true,
};

export const fileEditingAllowedList = ["pdf", "doc", "docx", "xls", "xlsx", "odt", "ods", "csv", "heic"];

export const FILTER_PREFIXES = {
  CLIENTS: "CON",
  INTERNAL: "TENANT",
  MY_FILES: "USR",
  RECENTS: "RECENTS",
};

export const CONVERTIBLE_EXTENSIONS = {
  bmp: true,
  csv: true,
  doc: true,
  docx: true,
  emf: true,
  eml: true,
  gif: true,
  heic: true,
  htm: true,
  html: true,
  jp2: true,
  jpeg: true,
  jpg: true,
  msg: true,
  ods: true,
  odt: true,
  pdf: true,
  png: true,
  ppt: true,
  pptx: true,
  rtf: true,
  svg: true,
  tif: true,
  tiff: true,
  txt: true,
  xls: true,
  xlsm: true,
  xlsx: true,
  xod: true,
  xps: true,
};

export const INDETERMINATE = "indeterminate";

export const SPECIAL_CHARACTERS_ERROR = `The following characters are not supported: \ / : * ? ” < > |`;

export const SECURE_LINK_MODAL_TYPES = {
  fileShare: "secureLinkFileShare",
  fileUpload: "secureLinkFileUpload",
};

export const DOCUMENT_AI_ALLOWED_FILE_TYPE = {
  jpeg: true,
  jpg: true,
  jp2: true,
  jpc: true,
  png: true,
  pdf: true,
  tiff: true,
  tif: true,
};

export const DOCUMENT_AI_FILE_SIZE_LIMIT = 500000000;

export const ESIGN_TEMPLATE_TAB_TYPES = [
  { name: "Presets", id: 1, type: "preset" },
  { name: "Team", id: 2, type: "team" },
  { name: "Private", id: 3, type: "private" },
  { name: "Drafts", id: 4, type: "draft" },
];

// \u00A0 is a non-breaking space to push line 2
export const esignFeatureBubble = {
  toggle: "toggle_esign_templates",
  title: "Enhanced eSign Experience: \u00A0\u00A0 Now Supports Templates!",
  strategy: "opt-in",
  hide: false,
  expirationDate: "2024-04-29",
  documentationUrl: "https://support.getcanopy.com/",
};

export const filesRebuildFeatureBubble = {
  toggle: "toggle_files_rebuild",
  title: "Files Interface Redesign",
  strategy: "opt-out",
  hide: false,
  expirationDate: "2024-10-22",
};
